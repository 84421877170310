import { pick } from 'lodash';
import { all, put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import actions from '../../../../actions';

export const PollClassStudentOperation = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE'
};

export default function* pollClassStudents(ids, operation) {
  if (!featureIsEnabled('data-recency') || !featureIsEnabled('data-recency-class-students') || !ids.length) {
    return;
  }
  const { orgId, classId, students } = yield select(state => ({
    orgId: state.classroomPage.orgId,
    classId: state.classroomPage.classroomId,
    students: pick(state.people.data, ids)
  }));
  const polls = Object.entries(students).reduce(
    (carry, [userId, { roleName: role, lastUpdatedTimeStamp }]) => ({
      ...carry,
      [role]: {
        orgId,
        classId,
        role,
        collection: 'classStudents',
        users: [
          ...(carry[role] ? carry[role].users : []),
          { userId, ...(operation === PollClassStudentOperation.UPDATE ? { lastUpdatedTimeStamp } : {}) }
        ],
        operation,
        target: 'users',
        responseEntityKey: 'userPollingStatus',
        identifier: 'userId',
        search: 'classStudents'
      }
    }),
    {}
  );
  yield all(Object.values(polls).map(poll => put(actions.pollEntitiesSynced(poll))));
}

export function pollClassStudentsAdded(ids) {
  return pollClassStudents(ids, PollClassStudentOperation.ADD);
}

export function pollClassStudentsUpdated(ids) {
  return pollClassStudents(ids, PollClassStudentOperation.UPDATE);
}

export function pollClassStudentsRemoved(ids) {
  return pollClassStudents(ids, PollClassStudentOperation.REMOVE);
}
