// Constants
import userRoles from '../../../../globals/userRoles';
import { isLtiMode } from '../../../../utils/platform';

const getClassworkLicencesActionButtons = (
  content,
  { toggleDetails = null, assignLicencesToClass = null, removeMaterialFromClass = null, manageStudentsAccess = null },
  { isTeacherAssignedToClass = false, isLicenceRemoved = false, showManageProductLocksOption = false },
  currentOrganisationLti = false
) => ({
  [userRoles.ORG_ADMIN]: [
    currentOrganisationLti
      ? {}
      : {
          title: content.classwork_manage_class_assignment_text_dropdown,
          action: assignLicencesToClass
        },
    showManageProductLocksOption ? { title: content.manage_students_access, action: manageStudentsAccess } : {},
    {
      title: content.org_licences_view_product_info_text,
      action: toggleDetails
    },
    !isLicenceRemoved
      ? {
          title: content.classwork_remove_class_assignment_text,
          action: removeMaterialFromClass
        }
      : {}
  ],
  [userRoles.TEACHER_ADMIN]: [
    ...(isLtiMode() // hide this entry in LTI HUB mode
      ? []
      : [
          {
            title: content.classwork_manage_class_assignment_text_dropdown,
            action: assignLicencesToClass
          }
        ]),
    showManageProductLocksOption ? { title: content.manage_students_access, action: manageStudentsAccess } : {},
    {
      title: content.org_licences_view_product_info_text,
      action: toggleDetails
    },
    !isLicenceRemoved
      ? {
          title: content.classwork_remove_class_assignment_text,
          action: removeMaterialFromClass
        }
      : {}
  ],
  [userRoles.TEACHER]: isTeacherAssignedToClass
    ? [
        {
          title: content.classwork_manage_class_assignment_text_dropdown,
          action: assignLicencesToClass
        },
        showManageProductLocksOption ? { title: content.manage_students_access, action: manageStudentsAccess } : {},
        {
          title: content.org_licences_view_product_info_text,
          action: toggleDetails
        },
        !isLicenceRemoved
          ? {
              title: content.classwork_remove_class_assignment_text,
              action: removeMaterialFromClass
            }
          : {}
      ]
    : [],
  [userRoles.LEARNER]: [
    {
      title: content.org_licences_view_product_info_text,
      action: toggleDetails
    }
  ]
});

export default getClassworkLicencesActionButtons;
