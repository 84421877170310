import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { BULK_UPLOAD } from '@oup/shared-node-browser/constants.js';
import { resetForm, showBulkUserInput } from '../../../../../../redux/reducers/enrolUser.reducer.js';
// Components
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
// Utils
import content from '../../../../../../utils/cmsContent.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants.js';
// Styling
import styles from './enrollUser.scss';
import { featureIsEnabled } from '../../../../../../globals/envSettings.js';

function EnrolUserBulkErrorReviewing({
  closePanel,
  resetAction,
  bulkUserInputAction,
  data,
  userTypeToEnrol = null,
  maximumAllowedRecords
}) {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [errorImageSrc, setErrorImageSrc] = useState('');
  const [otherErrors, setOtherErrors] = useState([]);

  const columnErrors = Object.values(BULK_UPLOAD.COLUMN_ERROR_MAP).reduce(
    (acc, value) => ({ ...acc, [value]: value }),
    {}
  );
  const fileErrors = Object.values(BULK_UPLOAD.ERROR_MAP).reduce((acc, value) => ({ ...acc, [value]: value }), {});

  const allErrors = { ...columnErrors, ...fileErrors };

  const errorPriority = [
    allErrors.LINE_EMPTY,
    allErrors.INVALID_TOO_MANY_RECORDS,
    allErrors.INVALID_EMPTY_FILE,
    allErrors.INVALID_HEADER_ROW_ONLY,
    allErrors.INVALID_HEADER_ROW,
    allErrors.INVALID_NUMBER_OF_COLUMNS
  ];

  const CMS = content.enrolUserBulkPage || {};
  const errorImagePath = '/media/hub/errors';

  const closePanelAndResetForm = () => {
    closePanel();
    setTimeout(resetAction, 300);
  };

  useEffect(() => {
    if (!data?.length) return;

    let errorFound = '';
    const hasError = (error, errorData) =>
      errorData
        .map(errorArray => errorArray.cmsErrorKeys.indexOf(error) !== -1)
        .reduce((prev, next) => prev || next, false);

    for (let i = 0; i < errorPriority.length; i += 1) {
      if (hasError(errorPriority[i], data)) {
        errorFound = errorPriority[i];
        break;
      }
    }
    switch (errorFound) {
      case allErrors.LINE_EMPTY: {
        setTitle(CMS.error_message_uploadError_intro_text);
        setSubtitle(CMS.error_message_uploadError_line_empty_subtitle);

        let errorImage;

        if (userTypeToEnrol) {
          errorImage = `bulk_upload_${userTypeToEnrol}_line_empty_error.svg`;
        } else if (featureIsEnabled('supervised-users')) {
          errorImage = 'bulk_upload_line_empty_error_without_username.svg';
        } else {
          errorImage = 'bulk_upload_line_empty_error.png';
        }

        setErrorImageSrc(`${errorImagePath}/${errorImage}`);
        break;
      }
      case allErrors.INVALID_TOO_MANY_RECORDS:
        setTitle(CMS.file_upload_error_title);
        setSubtitle(
          CMS.file_upload_error_subtitle_invalid_too_many_records?.replace(
            '[maxRecordsNumber]',
            maximumAllowedRecords || BULK_UPLOAD.MAX_ROWS
          )
        );

        break;
      case allErrors.INVALID_EMPTY_FILE:
      case allErrors.INVALID_HEADER_ROW_ONLY:
        setTitle(CMS.file_upload_error_title);
        setSubtitle(CMS.file_upload_error_subtitle_invalid_empty_file);
        break;
      case allErrors.INVALID_HEADER_ROW:
      case allErrors.INVALID_NUMBER_OF_COLUMNS: {
        setTitle(CMS.file_upload_error_title);
        setSubtitle(CMS.file_upload_error_subtitle_invalid_number_of_columns);

        let errorImage;

        if (userTypeToEnrol) {
          errorImage = `bulk_upload_${userTypeToEnrol}_missing_headers_error.svg`;
        } else if (featureIsEnabled('supervised-users')) {
          errorImage = 'bulk_upload_missing_headers_error_without_username.svg';
        } else {
          errorImage = 'bulk_upload_missing_headers_error_with_username.png';
        }

        setErrorImageSrc(`${errorImagePath}/${errorImage}`);
        break;
      }
      default:
        setTitle(CMS.error_message_uploadError_intro_text);
        setSubtitle(CMS.error_message_other_subtitle);
        setOtherErrors(
          data.map(({ row, cmsErrorKeys }) => ({
            rowNumber: row,
            error: cmsErrorKeys.map(val => CMS[val]).join(', ')
          }))
        );
    }
  }, [data]);

  return (
    <form
      className={styles.formContainer}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      {data.length > 0 ? (
        /* ERROR SCENARIO 1: Errors found in data: */
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink
                  isLhs={false}
                  text={CMS.close_panel_text}
                  action={userTypeToEnrol ? bulkUserInputAction : closePanelAndResetForm}
                />
              </div>

              <PopoutPanelIllustrationHeading
                title={title}
                subtitle={subtitle}
                illustrationSrc={HubIllustrationConstants.ERROR}
                illustrationAltText={HubIllustrationAltText.ERROR}
              />
            </div>
          }
          footerContent={<PopoutNavFooter backAction={userTypeToEnrol ? bulkUserInputAction : resetAction} />}
        >
          {!!errorImageSrc && (
            <div className={styles.errorListContainer}>
              <img className={styles.missingHeadersTableImage} src={errorImageSrc} alt="" />
            </div>
          )}
          {otherErrors.length > 0 && (
            <div className={styles.errorListContainer}>
              <table>
                <thead>
                  <th>Row</th>
                  <th>Error</th>
                </thead>
                <tbody>
                  {otherErrors.map(row => (
                    <tr key={row.rowNumber}>
                      <td>{row.rowNumber}</td>
                      <td>{row.error}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </ScrollContainer>
      ) : (
        /* ERROR SCENARIO 2: No error found in data, but error uploading: */
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink
                  isLhs={false}
                  text={CMS.close_panel_text}
                  action={userTypeToEnrol ? bulkUserInputAction : closePanelAndResetForm}
                />
              </div>

              <PopoutPanelIllustrationHeading
                title={CMS.error_message_uploadError_intro_text}
                illustrationSrc={HubIllustrationConstants.ERROR}
                illustrationAltText={HubIllustrationAltText.ERROR}
              />
            </div>
          }
          footerContent={<PopoutNavFooter backAction={userTypeToEnrol ? bulkUserInputAction : resetAction} />}
        />
      )}
    </form>
  );
}

EnrolUserBulkErrorReviewing.propTypes = {
  closePanel: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  bulkUserInputAction: PropTypes.func,
  maximumAllowedRecords: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.string.isRequired,
      value: PropTypes.string,
      cmsErrorKeys: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  userTypeToEnrol: PropTypes.string
};

export default connect(
  state => ({
    data: state.enrolUser.failedEntries,
    maximumAllowedRecords: state.enrolUser.maximumAllowedRecords
  }),
  {
    // Done button
    resetAction: resetForm,
    bulkUserInputAction: showBulkUserInput
  }
)(EnrolUserBulkErrorReviewing);
