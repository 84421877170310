import PropTypes from 'prop-types';
import React from 'react';
import Button from '@oup/shared-front-end/src/components/Button';
import withLocalizedContent from '../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './CookieBanner.scss';

import { isDev } from '../../globals/envSettings.js';

let browserInfo = '';

if (isDev()) {
  const browserInfoName = sessionStorage.getItem('browserInfoName')
    ? sessionStorage.getItem('browserInfoName')
    : 'unknown';
  const browserInfoVersion = sessionStorage.getItem('browserInfoVersion')
    ? sessionStorage.getItem('browserInfoVersion')
    : 'unknown';
  const browserInfoOS = sessionStorage.getItem('browserInfoOS') ? sessionStorage.getItem('browserInfoOS') : 'unknown';
  if (browserInfoName === 'unknown' && browserInfoVersion === 'unknown' && browserInfoOS === 'unknown') {
    browserInfo = '';
  } else {
    browserInfo = `Your browser is identifying itself as ${browserInfoName}, version ${browserInfoVersion} and is running on ${browserInfoOS}.`;
  }
}

function CookieBanner({ onAccept, localizedContent: { cookieBanner: content } }) {
  return (
    <article className={styles.cookieBanner} data-testid="COOKIE_BANNER">
      <div className="grid">
        <div className="row">
          <div className="col">
            <div className={styles.text}>
              <p>{content.cookie_message.replace('{browserInfo}', browserInfo)}</p>
            </div>
            <div className={styles.buttons}>
              <Button
                variant="filled"
                onClick={() => window.open(content.cookie_policy_url, '_blank')}
                text={content.learn_more_button}
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_LINK} />, placement: 'right' }}
                dataAttributes={{ testId: 'COOKIE_BANNER_FIND_MORE' }}
              />
              <Button
                id="cookieBannerClose"
                variant="filled"
                onClick={onAccept}
                text={content.continue_button}
                dataAttributes={{ testId: 'COOKIE_BANNER_CLOSE_BUTTON' }}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

CookieBanner.propTypes = {
  onAccept: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('cookieBanner')(CookieBanner);
