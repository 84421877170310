import { parse as parseQueryString } from 'query-string';

import { isEmbeddedInIframe, updateProductMetadataFromQuery } from '../../../../../utils/platform';

// TODO: fix dependency cycle
// eslint-disable-next-line import/no-cycle
import processAuthUniversal from './processAuth/universal.saga';
import processAuthEmbedded from './processAuth/embedded.saga';

/**
 *
 */
export default function* processAuth() {
  console.groupCollapsed('Process auth');
  let result;

  // Start processing based on the type of request
  if (isEmbeddedInIframe()) {
    result = yield processAuthEmbedded();
  } else {
    result = yield processAuthUniversal();
  }

  // Set paths after the login has happened with success
  const parsedQuery = parseQueryString(window.location.search);
  const { goto: gotoPath, embedded } = parsedQuery;

  const oidcReturnToPath = sessionStorage.getItem('oidcReturnToPath');
  sessionStorage.setItem('embedded-by-url', `${embedded === 'true'}`);

  if (gotoPath) {
    sessionStorage.setItem('goto-path', gotoPath);
  } else if (oidcReturnToPath) {
    sessionStorage.removeItem('oidcReturnToPath');
    sessionStorage.setItem('goto-path', oidcReturnToPath);
  }

  // Convert x-product-... options to object and store it to localStorage
  updateProductMetadataFromQuery(parsedQuery);

  console.groupEnd();
  return result;
}
