import { pick } from 'lodash';
import { put, select, take } from 'redux-saga/effects';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import userRoles from '../../../../../globals/userRoles';
import { CLEAR_FORM, submissionComplete, SUBMIT_FORM } from '../../../../reducers/addStudentsToClassroom';
import {
  addStudentsToClassroom as addStudentsToClassroomComplete,
  updateClassroomLimit
} from '../../../../reducers/data/classrooms';
import editClassroomApi from '../../../apiCalls/editClassroomApi';
import { pollClassStudentsAdded } from '../dataRecency/pollClassStudents';
import { triggerLoadClassroomEdit } from './edit';
import pickStudentIdsByRole from './pickStudentIdsByRole';
import { getCurrentPlatform, isHubMode } from '../../../../../utils/platform';
import getClassLink from '../../../../../structure/HubDashboardLayout/Services/getClassLink';
import getProgressLink from '../../../../../structure/HubDashboardLayout/Services/getProgressLink';

export default function* addStudentsToClassroom() {
  console.log('[addStudentsToClassroom Saga] Beginning');

  while (true) {
    console.log('[addStudentsToClassroom Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    console.log('[addStudentsToClassroom Saga] Form submitted. capturing data from state...');
    const {
      organisationId,
      classroomId,
      classroomName,
      selectedStudents,
      enableStudentLimit,
      studentLimit,
      origEnableStudentLimit,
      origStudentLimit,
      people
    } = yield select(state => ({
      organisationId: state.classroomPage.orgId,
      classroomId: state.classroomPage.classroomId,
      classroomName: state.classrooms.data[state.classroomPage.classroomId]?.name,
      selectedStudents: state.addStudentsToClassroom.selectedStudentIds,
      enableStudentLimit: state.addStudentsToClassroom.enableStudentLimit,
      studentLimit: state.addStudentsToClassroom.studentLimit,
      origEnableStudentLimit: !!state.classrooms.data[state.classroomPage.classroomId].studentLimit,
      origStudentLimit: state.classrooms.data[state.classroomPage.classroomId].studentLimit,
      people: state.people.data
    }));

    const selectedStudentDetails = pick(people, selectedStudents);
    const managedUsersAdded = pickStudentIdsByRole(selectedStudentDetails, userRoles.MANAGED_USER);
    const studentsAdded = pickStudentIdsByRole(selectedStudentDetails, userRoles.LEARNER);
    const payload = {
      name: classroomName,
      type: APP_CONSTANTS.ADD_STUDENT,
      platformCode: getCurrentPlatform(),
      ...(managedUsersAdded.length ? { managedUsersAdded } : {}),
      ...(studentsAdded.length ? { studentsAdded } : {})
    };

    // If the limit has been disabled, clear it
    if (!enableStudentLimit && enableStudentLimit !== origEnableStudentLimit) {
      payload.clearLimit = true;
    } else if (enableStudentLimit && studentLimit !== origStudentLimit) {
      // Payload is still enabled and the value has changed so include it
      payload.limit = studentLimit;
    }

    // if platform is hub
    if (isHubMode()) {
      payload.loginUrl = getProgressLink(getClassLink(classroomId), organisationId).substring(1);
    }

    console.log(
      `[addStudentsToClassroom Saga] Submitting request to add student IDs: ${selectedStudents} to class with ID: ${classroomId} and payload:`,
      payload
    );
    const response = yield editClassroomApi(organisationId, classroomId, payload);

    const editResult = response || {};
    const requestFailed = editResult.status !== 'success';
    const failedIds = (editResult.data || {}).failedIds || [];

    console.log('[addStudentsToClassroom Saga] Create Class request complete, showing confirmation page');
    yield put(submissionComplete(requestFailed, requestFailed ? selectedStudents : failedIds));

    if (!requestFailed) {
      const successfullyAddedStudentIds = selectedStudents.filter(id => !failedIds.includes(id));
      yield put(addStudentsToClassroomComplete(classroomId, successfullyAddedStudentIds));
      if (!enableStudentLimit && enableStudentLimit !== origEnableStudentLimit) {
        console.log(`[addStudentsToClassroom Saga] Clearing student limit on class ${classroomId} locally`);
        yield put(updateClassroomLimit(classroomId, null));
      } else if (enableStudentLimit && studentLimit !== origStudentLimit) {
        console.log(
          `[addStudentsToClassroom Saga] Setting student limit on class ${classroomId} locally:`,
          studentLimit
        );
        yield put(updateClassroomLimit(classroomId, studentLimit));
      }
      yield pollClassStudentsAdded(successfullyAddedStudentIds);
    }

    yield triggerLoadClassroomEdit();

    yield take(CLEAR_FORM);
  }
}
