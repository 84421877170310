import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './SiteFooter.scss';

import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import content from '../../utils/cmsContent.js';
import {
  useEltBranding,
  getCurrentPlatform,
  getPlatformFromQueryString,
  isOicMode,
  isCesMode,
  isMflMode
} from '../../utils/platform';
import { getPlatformTermsUrl, getPlatformSupportUrl, getIntegrationPlatformKey } from '../../globals/envSettings';
import { displayHeaderOrFooter } from '../../services/cptHelperMethods';

const enableSocialLinks = false; // switched off for launch
const showEltProducts = useEltBranding();

const listOneKeyRegex = /^linkListOne_listItem\d+_text$/;
const listTwoKeyRegex = /^linkListTwo_listItem\d+_text$/;
const listThreeKeyRegex = /^linkListThree_listItem\d+_text$/;
// const content = {
//   siteFooter: {
//     linkListOne_header: 'Talk to us',
//     linkListOne_listItem1_text: 'Tell us what you think',
//     linkListOne_listItem1_target: 'https://global.oup.com/1',
//     linkListOne_listItem2_text: 'Contact us',
//     linkListOne_listItem2_target: 'https://global.oup.com/contact',
//     linkListOne_listItem3_text: 'Help and support',
//     linkListOne_listItem3_target: 'https://global.oup.com/help',

//     linkListTwo_header: 'More from us',
//     linkListTwo_listItem1_text: 'English Language Teaching',
//     linkListTwo_listItem1_target: 'https://global.oup.com/1',
//     linkListTwo_listItem2_text: "Oxford Teacher's Club",
//     linkListTwo_listItem2_target: 'https://global.oup.com/2',
//     linkListTwo_listItem3_text: "Oxford Learner's Bookshelf",
//     linkListTwo_listItem3_target: 'https://global.oup.com/3',
//     linkListTwo_listItem4_text: "Oxford Learner's Dictionaries",
//     linkListTwo_listItem4_target: 'https://global.oup.com/4',

//     linkListThree_header: 'Who we are',
//     linkListThree_listItem1_text: 'Our mission',
//     linkListThree_listItem1_target: 'https://global.oup.com/1',
//     linkListThree_listItem2_text: 'Our history',
//     linkListThree_listItem2_target: 'https://global.oup.com/2',
//     linkListThree_listItem3_text: 'Annual report',
//     linkListThree_listItem3_target: 'https://global.oup.com/3',
//     linkListThree_listItem4_text: 'The way we work',
//     linkListThree_listItem4_target: 'https://global.oup.com/4',
//     linkListThree_listItem5_text: 'Working for OUP',
//     linkListThree_listItem5_target: 'https://global.oup.com/5',

//     privacyLink_text: 'Privacy Policy',
//     privacyLink_target: 'https://global.oup.com/privacy',
//     cookieLink_text: 'Cookie Policy',
//     cookieLink_target: 'https://global.oup.com/cookiepolicy',
//     termsLink_text: 'Terms and Conditions',
//     termsLink_target: 'https://global.oup.com/',
//     allyLink_text: 'Accessibility',
//     allyLink_target: 'https://global.oup.com/',

//     copyRight: 'Oxford University Press',
//     ally_title_text: 'link opens in a new tab',
//     footer_text:
//       "Oxford University Press is a department of the University of Oxford. It furthers the University's objective of excellence in research, scholarship, and education by publishing worldwide.",
//     twitter_icon_link: '#',
//     facebook_icon_link: '#',
//     youtube_icon_link: '#',
//     linkedin_icon_link: '#',
//     wordpress_icon_link: '#',

//     twitter_link_text: 'Link to OUP twitter',
//     facebook_link_text: 'Link to OUP Facebook',
//     youtube_link_text: 'Link to OUP Youtube',
//     linkedin_link_text: 'Link to OUP Linkedin',
//     wordpress_link_text: 'Link to OUP Wordpress'
//   }
// };

function SiteFooter({ darkLayout, showRecaptchaFooter = false }) {
  const CMS = content.siteFooter || {};
  const platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
  const supportUrl = getPlatformSupportUrl(platform);
  const termsUrl = getPlatformTermsUrl(platform) || CMS.termsLink_target;
  const [displayFooter, setDisplayFooter] = useState(true);
  const history = useHistory();

  useEffect(() => displayHeaderOrFooter(history, setDisplayFooter), [history]);

  return (
    displayFooter && (
      <footer
        className={classnames(styles.siteFooter, styles.footerRestyling, {
          [styles.darkBackground]: darkLayout,
          [styles.pushDown]: isOicMode()
        })}
      >
        {!isMflMode() && (
          <>
            {enableSocialLinks &&
              CMS.twitter_icon_link &&
              CMS.facebook_icon_link &&
              CMS.youtube_icon_link &&
              CMS.linkedin_icon_link &&
              CMS.wordpress_icon_link && (
                <div className={styles.socialStrip}>
                  <div className="grid">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <li>
                            {CMS.twitter_icon_link && (
                              <a href={CMS.twitter_icon_link} rel="noreferrer" target="_blank">
                                <SVGIcon glyph={GLYPHS.ICON_TWITTER_LOGO} />
                                <span className="a11y-hide">
                                  {CMS.twitter_link_text} {CMS.ally_opens_in_new_tab}
                                </span>
                              </a>
                            )}
                          </li>
                          <li>
                            {CMS.facebook_icon_link && (
                              <a href={CMS.facebook_icon_link} rel="noreferrer" target="_blank">
                                <SVGIcon glyph={GLYPHS.ICON_FACEBOOK_LOGO} />
                                <span className="a11y-hide">
                                  {CMS.facebook_link_text} {CMS.ally_opens_in_new_tab}
                                </span>
                              </a>
                            )}
                          </li>
                          <li>
                            {CMS.youtube_icon_link && (
                              <a href={CMS.youtube_icon_link} rel="noreferrer" target="_blank">
                                <SVGIcon glyph={GLYPHS.ICON_YOUTUBE_LOGO} />
                                <span className="a11y-hide">
                                  {CMS.youtube_link_text} {CMS.ally_opens_in_new_tab}
                                </span>
                              </a>
                            )}
                          </li>
                          <li>
                            {CMS.linkedin_icon_link && (
                              <a href={CMS.linkedin_icon_link} rel="noreferrer" target="_blank">
                                <SVGIcon glyph={GLYPHS.ICON_LINKEDIN_LOGO} />
                                <span className="a11y-hide">
                                  {CMS.linkedin_link_text} {CMS.ally_opens_in_new_tab}
                                </span>
                              </a>
                            )}
                          </li>
                          <li>
                            {CMS.wordpress_icon_link && (
                              <a href={CMS.wordpress_icon_link} rel="noreferrer" target="_blank">
                                <SVGIcon glyph={GLYPHS.ICON_WORDPRESS_LOGO} />
                                <span className="a11y-hide">
                                  {CMS.wordpress_link_text} {CMS.ally_opens_in_new_tab}
                                </span>
                              </a>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {showEltProducts && (
              <div className={classnames('grid', styles.topSection)}>
                <div className="row">
                  <div
                    className={classnames('col sm-show sm3 md2', styles.linkList, {
                      'xs-hide': !isOicMode()
                    })}
                  >
                    <h3>{CMS.linkListOne_header}</h3>
                    <ul>
                      {isOicMode() ? (
                        <li>
                          <a href={CMS.linkListOne_listItem2_target}>{CMS.linkListOne_listItem2_text}</a>
                        </li>
                      ) : (
                        Object.keys(CMS)
                          .filter(key => listOneKeyRegex.test(key))
                          .sort()
                          .map(key => (
                            <li key={key}>
                              <a
                                href={
                                  CMS[key] === 'Help and support' && supportUrl
                                    ? supportUrl
                                    : CMS[key.replace('_text', '_target')]
                                }
                                rel="noreferrer"
                                target="_blank"
                              >
                                {CMS[key]} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                              </a>
                            </li>
                          ))
                      )}
                    </ul>
                  </div>
                  <div
                    className={classnames('col sm-show sm3 md2', styles.linkList, {
                      'xs-hide': !isOicMode()
                    })}
                  >
                    <h3>{CMS.linkListTwo_header}</h3>

                    {isOicMode() ? (
                      <ul>
                        <li>
                          <a href={CMS.linkListTwo_listItem7_target}>{CMS.linkListTwo_listItem7_text}</a>
                        </li>
                        <li>
                          <a href={CMS.linkListTwo_listItem5_target}>{CMS.linkListTwo_listItem5_text}</a>
                        </li>
                        <li>
                          <a href={CMS.linkListTwo_listItem6_target}>{CMS.linkListTwo_listItem6_text}</a>
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        {Object.keys(CMS)
                          .filter(key => listTwoKeyRegex.test(key))
                          .sort()
                          .map(key => (
                            <li key={key}>
                              <a href={CMS[key.replace('_text', '_target')]} rel="noreferrer" target="_blank">
                                {CMS[key]} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                  <div
                    className={classnames('col sm-show sm3 md2', styles.linkList, {
                      'xs-hide': !isOicMode()
                    })}
                  >
                    <h3>{CMS.linkListThree_header}</h3>
                    <ul>
                      {isOicMode() ? (
                        <li>
                          <a href={CMS.linkListThree_listItem1_target}>{CMS.linkListThree_listItem1_text}</a>
                        </li>
                      ) : (
                        Object.keys(CMS)
                          .filter(key => listThreeKeyRegex.test(key))
                          .sort()
                          .map(key => (
                            <li key={key}>
                              <a href={CMS[key.replace('_text', '_target')]} rel="noreferrer" target="_blank">
                                <span className="a11y-hide">({CMS.linkListThree_header})</span> {CMS[key]}
                                <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                              </a>
                            </li>
                          ))
                      )}
                    </ul>
                  </div>

                  <div
                    className={classnames('col sm3 md6', styles.logoContainer, {
                      'align-items-center': !isOicMode()
                    })}
                  >
                    <p>{CMS.footer_text}</p>
                    <SVGIcon glyph={GLYPHS.OUP_LOGO_BLUE} />
                  </div>
                </div>
              </div>
            )}
            {/* eslint-disable-next-line react/no-danger */}
            {isCesMode() && showRecaptchaFooter && (
              // eslint-disable-next-line react/no-danger
              <p className={styles.Recaptcha} dangerouslySetInnerHTML={{ __html: CMS.recaptcha_html }} />
            )}
          </>
        )}
        <div className={styles.legalStrip}>
          <div className="grid">
            <div className="row">
              <div className="col">
                <ul>
                  {!(isOicMode() || isMflMode()) && (
                    <li>
                      <a href={supportUrl || CMS.helpAndSupport_target} target="_blank" rel="noreferrer">
                        {CMS.helpAndSupport_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                      </a>
                    </li>
                  )}
                  <li>
                    <a href={CMS.privacyLink_target} rel="noreferrer" target="_blank">
                      {CMS.privacyLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  {isMflMode() && (
                    <li>
                      <a href={CMS.legalNoticeLink_target} rel="noreferrer" target="_blank">
                        {CMS.legalNoticeLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                      </a>
                    </li>
                  )}
                  <li>
                    <a href={CMS.cookieLink_target} rel="noreferrer" target="_blank">
                      {CMS.cookieLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  <li>
                    <a href={termsUrl} rel="noreferrer" target="_blank">
                      {CMS.termsLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                    </a>
                  </li>
                  {!isMflMode() && (
                    <li>
                      <a href={CMS.allyLink_target} rel="noreferrer" target="_blank">
                        {CMS.allyLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                      </a>
                    </li>
                  )}
                  {isOicMode() && (
                    <li>
                      <a href={CMS.compatibilityCheckLink_target} rel="noreferrer" target="_blank">
                        {CMS.compatibilityCheckLink_text} <span className="a11y-hide">{CMS.ally_opens_in_new_tab}</span>
                      </a>
                    </li>
                  )}
                  <li className={styles.copy}>
                    &copy; {new Date().getUTCFullYear()} {CMS.copyRight}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  );
}

SiteFooter.propTypes = {
  darkLayout: PropTypes.bool.isRequired,
  showRecaptchaFooter: PropTypes.bool
};

export default connect(state => ({
  darkLayout: !state.identity.role
}))(SiteFooter);
