import * as t from '../actionTypes';

export const openNavigation = () => ({
  type: t.HUB_OPEN_SIDENAV
});

export const closeNavigation = () => ({
  type: t.HUB_CLOSE_SIDENAV
});

export const openRedeemModal = () => ({
  type: t.HUB_OPEN_REDEEM_MODAL
});

export const closeRedeemModal = () => ({
  type: t.HUB_CLOSE_REDEEM_MODAL
});

export const openEnterCodeModal = () => ({
  type: t.HUB_OPEN_ENTER_CODE_MODAL
});

export const closeEnterCodeModal = () => ({
  type: t.HUB_CLOSE_ENTER_CODE_MODAL
});

export const openStructuredContentPlayerModal = () => ({
  type: t.CPT_OPEN_STRUCTURED_CONTENT_PLAYER_MODAL
});

export const closeStructuredContentPlayerModal = () => ({
  type: t.CPT_CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL
});

export const openJoinClassWithCodeModal = () => ({
  type: t.HUB_OPEN_JOIN_CLASS_WITH_CODE_MODAL
});

export const closeJoinClassWithCodeModal = () => ({
  type: t.HUB_CLOSE_JOIN_CLASS_WITH_CODE_MODAL
});

export const openJoinPlacementTestWithCodeModal = () => ({
  type: t.HUB_OPEN_JOIN_PLACEMENT_TEST_WITH_CODE_MODAL
});

export const closeJoinPlacementTestWithCodeModal = () => ({
  type: t.HUB_CLOSE_JOIN_PLACEMENT_TEST_WITH_CODE_MODAL
});

export const openCreateClassModal = isTeacherInClass => ({
  type: t.HUB_OPEN_CREATE_CLASS_MODAL,
  isTeacherInClass: typeof isTeacherInClass === 'boolean' ? isTeacherInClass : true
});

export const closeCreateClassModal = () => ({
  type: t.HUB_CLOSE_CREATE_CLASS_MODAL
});

export const toggleArchiveClassModal = () => ({
  type: t.HUB_TOGGLE_ARCHIVE_CLASS_MODAL
});

export const openEditMyAccountModal = () => ({
  type: t.HUB_OPEN_EDIT_ACCOUNT_MODAL
});

export const openAddTeacherModal = () => ({
  type: t.HUB_OPEN_ADD_TEACHERS_MODAL
});

export const closeAddTeacherModal = () => ({
  type: t.HUB_CLOSE_ADD_TEACHERS_MODAL
});

export const toggleAddStudentsModal = () => ({
  type: t.HUB_TOGGLE_ADD_STUDENTS_MODAL
});

export const toggleAssessmentLicenceTab = (id = '') => ({
  type: t.HUB_TOGGLE_STATUS_STUDENTS_ASSESSMENT_LICENCE_TAB,
  assessmentTestId: id
});

export const closeEditMyAccountModal = () => ({
  type: t.HUB_CLOSE_EDIT_ACCOUNT_MODAL
});

export const selectMyDashboardView = myDashboardView => ({
  type: t.HUB_SELECT_MY_DASHBOARD_VIEW,
  myDashboardView
});

export const showSelfSelectRoleModal = showSelfSelectedModal => ({
  type: t.HUB_SHOW_SELF_SELECT_ROLE_MODAL,
  showSelfSelectedModal
});

export const showSelfRegisteredUserCreatePlacementOrClassModal = showSelfRegisteredUserModal => ({
  type: t.HUB_SHOW_SELF_REGISTERED_USER_MODAL,
  showSelfRegisteredUserModal
});

export const openEditClassroomModal = () => ({
  type: t.HUB_OPEN_EDIT_CLASSROOM_MODAL
});

export const closeEditClassroomModal = () => ({
  type: t.HUB_CLOSE_EDIT_CLASSROOM_MODAL
});

export const openAddLicensesModal = () => ({
  type: t.HUB_OPEN_ADD_LICENSES_MODAL
});

export const closeAddLicensesModal = () => ({
  type: t.HUB_CLOSE_ADD_LICENSES_MODAL
});

export const openEditOrgModal = () => ({
  type: t.HUB_OPEN_EDIT_ORG_MODAL
});

export const closeEditOrgModal = () => ({
  type: t.HUB_CLOSE_EDIT_ORG_MODAL
});

export const toggleOrgUsagePanel = () => ({
  type: t.HUB_TOGGLE_ORG_USAGE_PANEL
});

export const toggleMaterialsToClassModal = materialsToClassModalContent => ({
  type: t.HUB_TOGGLE_MATERIALS_TO_CLASS_MODAL,
  materialsToClassModalContent
});

export const closeMaterialsToClassModal = materialsToClassModalContent => ({
  type: t.HUB_CLOSE_MATERIALS_TO_CLASS_MODAL,
  materialsToClassModalContent
});

export const setMaterialsContext = materialsToClassModalContent => ({
  type: t.HUB_SET_MATERIALS_CONTEXT,
  materialsToClassModalContent
});

export const openRecallLicenceModal = () => ({
  type: t.HUB_OPEN_RECALL_LICENCE_MODAL
});

export const closeRecallLicenceModal = () => ({
  type: t.HUB_CLOSE_RECALL_LICENCE_MODAL
});

export const toggleOrgModalOpen = () => ({
  type: t.HUB_TOGGLE_ORG_MODAL
});
export const toggleClassModalOpen = () => ({
  type: t.HUB_TOGGLE_CLASS_MODAL
});

export const toggleSetTestModalOpen = () => ({
  type: t.HUB_TOGGLE_SET_TEST_MODAL
});

export const openOnboardingWizard = () => ({
  type: t.HUB_OPEN_ONBOARDING_WIZARD
});

export const closeOnboardingWizard = () => ({
  type: t.HUB_CLOSE_ONBOARDING_WIZARD
});

export const openPlacementTestOnboardingWizard = () => ({
  type: t.HUB_OPEN_PLACEMENT_TEST_ONBOARDING_WIZARD
});

export const closePlacementTestOnboardingWizard = () => ({
  type: t.HUB_CLOSE_PLACEMENT_TEST_ONBOARDING_WIZARD
});

export const openPlacementTestPanel = () => ({
  type: t.HUB_OPEN_PLACEMENT_TEST_PANEL
});

export const closePlacementTestPanel = () => ({
  type: t.HUB_CLOSE_PLACEMENT_TEST_PANEL
});

export const openPlacementTestSessionInformationModal = () => ({
  type: t.HUB_OPEN_PLACEMENT_TEST_SESSION_INFORMATION_MODAL
});

export const closePlacementTestSessionInformationModal = () => ({
  type: t.HUB_CLOSE_PLACEMENT_TEST_SESSION_INFORMATION_MODAL
});

export const toggleDeploymentToolModal = () => ({
  type: t.HUB_TOGGLE_DEPLOYMENT_MODAL
});

export const openMissingLicenseModal = () => ({
  type: t.HUB_OPEN_MISSING_LICENSE_MODAL
});

export const closeMissingLicenseModal = () => ({
  type: t.HUB_CLOSE_MISSING_LICENSE_MODAL
});

export const openSelfRegisteredWizard = () => ({
  type: t.HUB_OPEN_SELF_REGISTERED_WIZARD
});

export const closeSelfRegisteredWizard = () => ({
  type: t.HUB_CLOSE_SELF_REGISTERED_WIZARD
});
