import { take, select } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch.js';
import { TRIGGER_LOGOUT } from '../../../../reducers/identity.reducer.js';
import authSettings from '../../../../../globals/authSettings.js';
import { clearCredentialData } from '../../../../../utils/credentials';
import { isOrbMode, epsPlatformOlbOffline, isLtiMode } from '../../../../../utils/platform';
import envSettings, {
  isOIDCStandard,
  getIntegrationPlatformKey,
  getOidcClientIdByPlatformCode,
  getEpsAdminDashboardBaseUrl
} from '../../../../../globals/envSettings';
import initAuth0 from '../../../../../globals/oidcSettings';
import { getProviderId, prepareTargetUrl } from '../../../../../utils/url';

const getPostLogoutUrl = () => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  const providerId = getProviderId();
  const cancelledAuthTarget = `${protocol}//${hostname}${port}/offlineApp/AUTH_CANCELLED`;
  const offlineAppInitialState = sessionStorage.getItem('offlineAppInitialState');

  let targetUrl = prepareTargetUrl();
  const offlineAppActionType = sessionStorage.getItem('offlineAppActionType');
  const isAuthCancelled = offlineAppActionType === 'AUTH_CANCELLED';

  // TODO: this looks like a bug - it is set on render (not on logout)
  // but I am leaving it because I cannot know if it is intentional or
  // if this apparent bug is relied on - suggest moving this to completeLogout()
  if (offlineAppActionType) {
    sessionStorage.removeItem('offlineAppActionType');
  }

  if (isOrbMode()) {
    targetUrl = envSettings.integrations.orb.logoutBaseUrl;
  } else if (
    targetUrl &&
    providerId &&
    getIntegrationPlatformKey(providerId) === epsPlatformOlbOffline &&
    isAuthCancelled
  ) {
    targetUrl = `${cancelledAuthTarget}${window.location.search}`;
  } else if (offlineAppInitialState && isAuthCancelled) {
    targetUrl = cancelledAuthTarget;
  }

  if (!targetUrl) {
    targetUrl = `${protocol}//${hostname}${port}`;
  }

  return targetUrl;
};

const getUrlEncodedRedirectURL = () => encodeURIComponent(getPostLogoutUrl());

export const getLogoutUrl = roleName => {
  const idp = roleName === 'MANAGED_USER' ? 'idp2' : 'idp';
  return `${authSettings.idpUrl}/${idp}/profile/Logout?target=${getUrlEncodedRedirectURL()}`;
};

export function* completeLogout() {
  console.log('[completeLogout] Logout triggered. Clearing stored credentials...');
  clearCredentialData(true);

  const impersonationToken = localStorage.getItem('impersonation-token');
  const ltiToken = localStorage.getItem('lti-token');
  const dateString = new Date().toISOString();

  // TODO: this thing needs work - it does strange things all over the place
  localStorage.removeItem('user-info');

  if (isLtiMode() || ltiToken) {
    // making sure users that logout while using a launch token
    // are not redirected to a page that requires auth0/shibboleth login
    // LTI mode is only available on HUB and the route is marked as public in src/globals/authSettings.js
    // the correct fix is tightly linked to the implementation of launch tokens
    // localStorage.removeItem('lti-token');
    localStorage.setItem('lti-token-last-terminated', dateString);
    localStorage.setItem('lti-token', 'terminated');
    window.location.href = '/logged-out';
    return;
  }

  if (impersonationToken) {
    // clear the impersonation, but allow the log out to complete
    localStorage.removeItem('impersonation-token');
    localStorage.removeItem('lti-token'); // just in case...
  }

  const redirectUrlAfterLogout = impersonationToken ? getEpsAdminDashboardBaseUrl() : getPostLogoutUrl();

  if (isOIDCStandard(authSettings.idpStandard)) {
    console.log('[completeLogout] Logout triggered. redirect to Idp...');

    const logoutOptions = { returnTo: redirectUrlAfterLogout };
    const providerId = getProviderId();

    if (providerId) {
      const integrationKey = getIntegrationPlatformKey(providerId);
      logoutOptions.client_id = getOidcClientIdByPlatformCode(integrationKey);
    }

    yield initAuth0().then(auth0 => auth0.logout(logoutOptions));
    yield new Promise(() => {});
  }

  const rightSuiteToken = yield select(state => state.identity.auth.rightSuiteToken);
  const roleName = yield select(state => state.identity.role);

  console.log(
    `[completeLogout] Making request to clear RightSuite session for token via CES API layer: ${rightSuiteToken}`
  );
  // This wait may look visually slow to the user
  let logoutFail = false;
  try {
    yield signedFetch('RightSuiteLogout', `${__API_BASE__}/logout`, 'POST', {
      sessionToken: rightSuiteToken
    });
  } catch (err) {
    console.log('[completeLogout] logout api call failed');
    logoutFail = true;
  }

  if (!logoutFail) {
    console.log('[completeLogout] Redirecting to homepage');
    // window.location.href = getLogoutUrl();

    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', getLogoutUrl(roleName));
    ifrm.style.cssText = 'z-index:-1; width:0px; height:0px; display:none;';
    document.body.appendChild(ifrm);

    ifrm.addEventListener('load', () => {
      window.location.href = redirectUrlAfterLogout;
    });

    // Block while page is redirected
    yield new Promise(() => {});
  }
}

export default function* handleLogout() {
  console.log('[handleLogout] Waiting for user to press the logout button');
  const action = yield take(TRIGGER_LOGOUT);
  yield completeLogout(action.withoutRedirect);
}
