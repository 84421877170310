import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch';

export default function* getApiContentFetch(params) {
  const URL = `${__API_BASE__}/content/eltcore?contentCode=${params.contentCode}&role=${params.role}`;

  const headers = { 'Content-Type': JSON_ENCODED, Accept: API_VERSIONS.API_VERSION_0_2 };

  return yield signedFetch('getCptContent', URL, 'GET', undefined, headers);
}
