import * as t from '../actionTypes';

export const checkActivationCodeRequest = activationCode => ({
  type: t.CHECK_ACTIVATION_CODE_REQUEST,
  activationCode
});

export const validateActivationCode = () => ({
  type: t.VALIDATE_ACTIVATION_CODE
});

export const validateVstActivationCode = () => ({
  type: t.VALIDATE_VST_ACTIVATION_CODE
});

export const setVstActivationCode = activationCode => ({
  type: t.SET_VST_ACTIVATION_CODE,
  activationCode
});

export const setGuestEmailId = emailId => ({
  type: t.SET_GUEST_EMAIL_ID,
  emailId
});

export const validateGuestEmailId = () => ({
  type: t.VALIDATE_GUEST_EMAIL_ID
});

export const setActivationCode = activationCode => ({
  type: t.SET_ACTIVATION_CODE,
  activationCode
});

// Reset the form
export const resetActivationCode = () => ({
  type: t.RESET_ACTIVATION_CODE
});

export const checkActivationCodeRequestSuccess = data => ({
  type: t.CHECK_ACTIVATION_CODE_REQUEST_SUCCESS,
  data
});

export const checkActivationCodeRequestFailure = data => ({
  type: t.CHECK_ACTIVATION_CODE_REQUEST_FAILURE,
  data
});

export const resetErrorMessageCode = () => ({
  type: t.RESET_ERROR_MESSAGE_CODE
});

// Redeem code guest
export const redeemCodeRequest = payload => ({
  type: t.REDEEM_CODE_REQUEST,
  payload
});

export const redeemCodeRequestSuccess = () => ({
  type: t.REDEEM_CODE_SUCCESS
});

export const redeemCodeRequestFailure = data => ({
  type: t.REDEEM_CODE_FAILURE,
  data
});
