import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Component imports
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import PanelHeading from '../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer.js';
import PopoutNavFooter from '../../components/PopoutNavFooter/PopoutNavFooter';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

import Validation from '../../components/Validation/Validation.js';
import { submitForm, setClassCode, validateClassCode } from '../../redux/reducers/joinClass.reducer.js';
import cmsContent from '../../utils/cmsContent.js';
import Button, { buttonTypes } from '../../components/Button/Button';
import styles from './JoinClass.scss';

import { isHubMode } from '../../utils/platform';
import { featureIsEnabled } from '../../globals/envSettings.js';

const USER_ALREADY_IN_CLASS = 'User already in class';

function JoinClassCodeInput({
  closePanel,
  submitFormAction,
  classCodeValue,
  setClassCodeAction,
  validateClassCodeAction,
  classCodeValueIsValid,
  classCodeValueIsError,
  hasSpecialCharError,
  hasInvalidLengthError,
  errorMessage,
  content,
  userRole
}) {
  const CMS = cmsContent.addToProfile || {};
  const isTeacherUsingStudentCode = () =>
    ['TEACHER', 'TEACHER_ADMIN'].includes(userRole) && classCodeValue.startsWith('S-');
  const isStudentUsingTeacherCode = () => ['USER', 'LEARNER'].includes(userRole) && classCodeValue.startsWith('T-');
  const isError = featureIsEnabled('opt-main-features')
    ? classCodeValueIsError || isTeacherUsingStudentCode() || isStudentUsingTeacherCode()
    : classCodeValueIsError;
  const displayedErrorMessage = () => {
    if (featureIsEnabled('opt-main-features')) {
      if (hasSpecialCharError) {
        return content.special_char_error;
      }
      if (hasInvalidLengthError) {
        return content.invalid_length_error;
      }
      if (isStudentUsingTeacherCode()) {
        return content.student_using_teacher_code_error;
      }
      if (isTeacherUsingStudentCode()) {
        return content.teacher_using_student_code_error;
      }
    }
    if (errorMessage?.message === USER_ALREADY_IN_CLASS) {
      return content.user_already_in_class;
    }
    return content.class_code_min_length_error;
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div className={styles.header}>
            <div className={styles.close}>
              <Button
                text={CMS.close_panel_text}
                customClassName={classNames('panelNavLink')}
                onClick={closePanel}
                type={buttonTypes.CLOSE_BOLD}
              />
            </div>
            <PanelHeading title={content.enter_a_code_text} subtitle={content.join_your_class_subtext} />
          </div>
        }
        footerContent={
          isHubMode() ? (
            <PopoutNavFooter
              nextButtonText={content.primary_button_text}
              nextAction={submitFormAction}
              nextButtonDisabled={!classCodeValueIsValid}
              backAction={closePanel}
              backButtonText={content.button_cancel_text}
            />
          ) : (
            <div style={{ margin: '10px' }}>
              <Button
                id="ClassCodeSubmit"
                type={buttonTypes.PRIMARY}
                text={content.primary_button_text}
                fullWidth
                onClick={submitFormAction}
                disabled={!classCodeValue}
              />
            </div>
          )
        }
      >
        <div style={{ padding: '1rem' }}>
          <p className="gin-top2">{content.write_code_text}</p>
          <Validation
            forId="enterAccessCode"
            isValid={classCodeValueIsValid}
            isError={isError}
            message={isError ? displayedErrorMessage() : ''}
          >
            <TextInput
              id="enterAccessCode"
              placeholder={content.enter_a_code_placeholder}
              maxLength={36}
              label={content.enter_your_code_label}
              value={classCodeValue}
              onChange={event => setClassCodeAction(event.target.value)}
              onBlur={validateClassCodeAction}
            />
          </Validation>

          {featureIsEnabled('opt-main-features') && (
            <div className={styles.notificationView}>
              <div>
                <SVGIcon className={styles.icon} glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
              </div>
              <div>
                <span>{content.class_joining_code_notification_label}</span>
              </div>
            </div>
          )}
        </div>
        {!featureIsEnabled('opt-main-features') && (
          <div className={styles.link}>
            <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
              {content.learn_more_about_codes_text}
            </TextLink>
          </div>
        )}
      </ScrollContainer>
    </form>
  );
}

JoinClassCodeInput.propTypes = {
  closePanel: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  classCodeValue: PropTypes.string.isRequired,
  setClassCodeAction: PropTypes.func.isRequired,
  validateClassCodeAction: PropTypes.func.isRequired,
  classCodeValueIsValid: PropTypes.bool.isRequired,
  classCodeValueIsError: PropTypes.bool.isRequired,
  hasSpecialCharError: PropTypes.bool.isRequired,
  hasInvalidLengthError: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  errorMessage: PropTypes.object,
  userRole: PropTypes.string
};

export default connect(
  state => ({
    file: state.joinClass.file,
    isFileSelected: state.joinClass.isFileSelected,
    classCodeValue: state.joinClass.classCodeValue,
    classCodeValueIsValid: state.joinClass.classCodeValueIsValid,
    classCodeValueIsError: state.joinClass.classCodeValueIsError,
    hasSpecialCharError: state.joinClass.hasSpecialCharError,
    hasInvalidLengthError: state.joinClass.hasInvalidLengthError,
    errorMessage: state.joinClass.errorMessage
  }),
  {
    submitFormAction: submitForm,
    setClassCodeAction: setClassCode,
    validateClassCodeAction: validateClassCode
  }
)(JoinClassCodeInput);
