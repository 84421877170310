import { combineReducers } from 'redux';

// Generic reducers
import app from './reducers/app.reducer.js';
import selection from './reducers/selection.js';
import invites from './reducers/invitationsBanner.reducer.js';

// Authorisation reducers
import registration from './reducers/registration/registration.reducer.js';
import identity from './reducers/identity.reducer.js';

// Data Store reducers
import people from './reducers/data/people.js';
import classrooms from './reducers/data/classrooms.js';
import organisations from './reducers/data/organisations.reducer.js';
import products from './reducers/data/products.reducer.js';
// Search store
import forms from './reducers/data/forms.reducer.js';
import fields from './reducers/data/fields.reducer.js';
import search from './reducers/data/search.reducer.js';
import supportArticles from './reducers/data/supportArticles.reducer.js';

// User reducers
import userProfile from './reducers/user/userProfile.reducer.js';
import archiveUsers from './reducers/archiveUsers.reducer.js';
import unlockAccount from './reducers/unlockAccount.reducer.js';
import restoreUsers from './reducers/restoreUsers.js';

// Institution Page reducer
import institutionPage from './reducers/institutionPage.reducer.js';

// Organisation Page and Panels reducers
import profilePage from './reducers/profilePage.reducer.js';
import organisationPage from './reducers/organisationPage.reducer.js';
import classroomCreate from './reducers/classroomCreate.js';
import placementTestSessionCreate from './reducers/placementTestSessionCreate.js';
import loadPlacementTestsReducer from './reducers/placementTests.reducer.js';
import loadPlacementTestResultsReducer from './reducers/placementTestResults.reducer.js';
import enrolUser from './reducers/enrolUser.reducer.js';

// Classroom Page and Panels reducers
import classroomPage from './reducers/classroomPage.js';
import classroomEdit from './reducers/classroomEdit.js';
import classroomArchive from './reducers/classroomArchive.js';
import addStudentsToClassroom from './reducers/addStudentsToClassroom.js';
import addTeachersToClassroom from './reducers/addTeachersToClassroom.js';
import removeStudentsFromClassroom from './reducers/removeStudentsFromClassroom.js';
import removeTeachersFromClassroom from './reducers/removeTeachersFromClassroom.js';

// Reducer for saved settings
import savedSettings from './reducers/savedSettings';

import library from './reducers/library/library.reducer.js';
import addToLibrary from './reducers/addToLibrary.reducer.js';
import assignLearningMaterial from './reducers/assignLearningMaterial.reducer.js';
import removeLearningMaterial from './reducers/removeLearningMaterial.reducer.js';

import licenceRecall from './reducers/user/licenceRecall.reducer.js';

// Support and Contact-us reducers:
import supportSearch from './reducers/support/supportSearch.reducer.js';

// TR Panel reducers:
import teacherResources from './reducers/teacherResources.js';

// NGS
import ngsLibrary from './reducers/ngs/library.reducer.js';
import ngsTeacherAssignments from './reducers/ngs/teacherAssignments.reducer.js';
import ngsTeacherReports from './reducers/ngs/teacherReports.reducer.js';
import ngsStudentHomework from './reducers/ngs/studentHomework.reducer.js';
import ngsStudentMarks from './reducers/ngs/studentMarks.reducer.js';
import ngsCourse from './reducers/ngs/course.reducer.js';
import ngsCourseBook from './reducers/ngs/courseBook.reducer.js';
import ngsCourseResource from './reducers/ngs/courseResource.reducer.js';
import ngsLTIOpener from './reducers/ngs/ltiOpener.reducer.js';

// ORB
import orbUserPreferences from './reducers/orb/userPreferences.reducer.js';
import orbTeacherResources from './reducers/orb/teacherResources.reducer.js';
import orbDownloadAppUrl from './reducers/orb/downloadAppUrl.reducer.js';

// OALD
import activationCode from './reducers/activationCode/activationCode.reducer.js';
import addToProfile from './reducers/addToProfile.reducer.js';

// Sign In Card
import userSignInCard from './reducers/userSignInCard.reducer';

// polling saga
import clientPolling from './reducers/polling.reducer';

import downloadSigninCardProcess from './reducers/downloadSigninCardProcess.reducer';

import studentRequestToJoinAClass from './reducers/studentRequestToJoinAClass.reducer.js';
import manageJoinAClassRequest from './reducers/manageJoinAClassRequest.reducer.js';
// HUB
import hubCourses from './reducers/hub/courses.reducer';
import hubUi from './reducers/hub/ui.reducer';
import hubResourceDownload from './reducers/hub/resource.download.reducer';
import hubPeople from './reducers/hub/hubPeople.reducer';
import hubRemoveUsersFromClassroom from './reducers/hub/hubRemoveUsersFromClassroom.reducer';
import hubCourseMaterialFilters from './reducers/hub/hubCourseMaterialFilters.reducer';
import hubProfileCourseMaterialFilters from './reducers/hub/hubProfileCourseMaterialFilters.reducer';
import hubAdaptedTests from './reducers/hub/hubAdaptedTests.reducer';

import editUserAccountModal from './reducers/editUserAccountModal.reducer';

// Structured Content Player
import structuredContentPlayer from './reducers/cpt/structuredContentPlayer.reducer';
import contentPreview from './reducers/contentPreview.reducer';
import coursePreview from './reducers/coursePreview.reducer';
import assetsPage from './reducers/assetsPage.reducer.js';

// JOIN CLASS
import joinClass from './reducers/joinClass.reducer';

// Download student data
import downloadStudentData from './reducers/downloadStudentData.reducer';

// Product Upload
import productUpload from './reducers/productUpload.reducer.js';
import productUploadLogs from './reducers/productUploadLogs.reducer.js';
import productPublish from './reducers/productPublish.reducer.js';
import productUploadCheckLogs from './reducers/productUploadCheck.reducer.js';

// Course
import courseUpload from './reducers/courseUpload.reducer.js';
import coursePublish from './reducers/coursePublish.reducer.js';

// OUP Admin page
import generateReport from './reducers/generateReport.reducer.js';

// ActivationCodes Dashboard
import activationCodeDashboard from './reducers/activationCodeDashboard/activationCodeDashboard.reducer.js';

// User Console
import userConsole from './reducers/userConsole/userConsole.reducer.js';
import userConsoleSearchEdit from './reducers/userConsole/userConsoleSearchEdit.reducer.js';

// ActivationCodes Dashboard
import activationCodeBatchDashboard from './reducers/activationCodeBatchDashboard/activationCodeBatchDashboard.reducer.js';

// Products Dashboard
import productExternalIds from './reducers/product/getExternalIds.reducer.js';

// Product Search
import searchProduct from './reducers/product/searchProduct.reducer.js';

// Course Contents
import courseContents from './reducers/cpt/courseContents.js';

// NGI Products
import getOpenProducts from './reducers/ngi/getOpenProducts.reducer.js';
import getLinkedProducts from './reducers/ngi/getLinkedProducts.reducer.js';
import getJwtAndDeeplinkReturnUrl from './reducers/ngi/getJwtAndDeeplinkReturnUrl.reducer.js';
import processLineItemsBatches from './reducers/ngi/processLineItemsBatches.reducer.js';
import getContentSelectorStatus from './reducers/ngi/getContentSelectorStatus.reducer.js';
import invalidateContentSelectorGroup from './reducers/ngi/invalidateContentSelectorGroup.reducer.js';

import userPlatformStatistics from './reducers/userPlatformStatistics.reducer.js';
import ngiContentSelector from './reducers/ngi/ngiContentSelector.reducer.js';

import * as reducers from './reducers';

export default combineReducers({
  // Generic reducers
  app,
  selection,
  invites,

  // Authorisation reducers
  registration,
  identity,

  // Data Store reducers
  people,
  classrooms,
  organisations,
  products,
  // Search
  forms,
  fields,
  search,
  supportArticles,

  // User Panel reducers
  userProfile,
  archiveUsers,
  unlockAccount,
  restoreUsers,

  // Institution page
  institutionPage,

  // Organisation Page and Panels reducers
  profilePage,
  organisationPage,
  classroomCreate,
  placementTestSessionCreate,
  loadPlacementTestsReducer,
  loadPlacementTestResultsReducer,
  enrolUser,

  // Classroom Page and Panels reducers
  classroomPage,
  classroomEdit,
  classroomArchive,
  addStudentsToClassroom,
  addTeachersToClassroom,
  removeStudentsFromClassroom,
  removeTeachersFromClassroom,

  // Saved settings
  savedSettings,

  library,
  addToLibrary,
  assignLearningMaterial,
  removeLearningMaterial,

  licenceRecall,

  // Support and Contact-us reducers:
  supportSearch,

  // TR Panel reducers:
  teacherResources,

  // Sign In Card
  userSignInCard,

  // Polling
  clientPolling,

  // NGS
  ngsLibrary,
  ngsTeacherAssignments,
  ngsTeacherReports,
  ngsStudentHomework,
  ngsStudentMarks,
  ngsCourse,
  ngsCourseBook,
  ngsCourseResource,
  ngsLTIOpener,

  studentRequestToJoinAClass,
  manageJoinAClassRequest,
  // ORB
  orbUserPreferences,
  orbTeacherResources,
  orbDownloadAppUrl,

  activationCode,
  addToProfile,

  // downloadSigninCard
  downloadSigninCardProcess,

  hubCourses,
  hubAdaptedTests,
  hubUi,
  hubResourceDownload,
  hubPeople,

  editUserAccountModal,

  // structured content player
  structuredContentPlayer,
  contentPreview,
  coursePreview,
  assetsPage,

  hubRemoveUsersFromClassroom,
  hubCourseMaterialFilters,
  hubProfileCourseMaterialFilters,

  // join class
  joinClass,

  downloadStudentData,

  productUploadLogs,
  productUpload,
  productUploadCheckLogs,
  productPublish,
  generateReport,

  courseUpload,
  coursePublish,

  activationCodeDashboard,

  userConsole,
  userConsoleSearchEdit,

  activationCodeBatchDashboard,

  productExternalIds,

  searchProduct,

  courseContents,

  // NGI
  getOpenProducts,
  getLinkedProducts,
  getJwtAndDeeplinkReturnUrl,
  ngiContentSelector,
  processLineItemsBatches,
  getContentSelectorStatus,
  invalidateContentSelectorGroup,

  userPlatformStatistics,

  ...reducers
});
