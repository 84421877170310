import React from 'react';
import propTypes from 'prop-types';

import Heading from '@oup/shared-front-end/src/components/Heading';
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import Select from '@oup/shared-front-end/src/components/Select';
import CheckboxGroup from '@oup/shared-front-end/src/components/CheckboxGroup';
import Button from '@oup/shared-front-end/src/components/Button';

import withLocalizedContent from '../../language/withLocalizedContent';
import Card from '../../components/Card/Card';

import styles from './OTCPlatformRegistrationForm.scss';

function OTCPlaformRegistrationForm({ localizedContent }) {
  const { otcPlatformRegistrationForm, countryCodes } = localizedContent;

  const professionalInterestCheckboxOptions = [
    {
      id: 'one',
      name: 'one',
      value: 'test',
      label: otcPlatformRegistrationForm.professional_interest_checkox_option_young_learners,
      checked: false
    },
    {
      id: 'two',
      name: 'two',
      value: 'test',
      label: otcPlatformRegistrationForm.professional_interest_checkox_option_teenagers,
      checked: false
    },
    {
      id: 'three',
      name: 'three',
      value: 'test',
      label: otcPlatformRegistrationForm.professional_interest_checkox_option_adults,
      checked: false
    },
    {
      id: 'four',
      name: 'four',
      value: 'test',
      label: otcPlatformRegistrationForm.professional_interest_checkox_option_elt,
      checked: false
    },
    {
      id: 'five',
      name: 'five',
      value: 'test',
      label: otcPlatformRegistrationForm.professional_interest_checkox_option_exams,
      checked: false
    },
    {
      id: 'six',
      name: 'six',
      value: 'test',
      label: otcPlatformRegistrationForm.professional_interest_checkox_option_applied_linguistics,
      checked: false
    },
    {
      id: 'seven',
      name: 'seven',
      value: 'test',
      label: otcPlatformRegistrationForm.professional_interest_checkox_option_professional_development,
      checked: false
    }
  ];

  const professionalRoleDropdownOptions = [
    { value: 'teacher', text: otcPlatformRegistrationForm.professional_role_options_teacher },
    { value: 'teacher_trainer', text: otcPlatformRegistrationForm.professional_role_options_teacher_trainer },
    { value: 'trainee_teacher', text: otcPlatformRegistrationForm.professional_role_options_trainee_teacher },
    { value: 'freelance_teacher', text: otcPlatformRegistrationForm.professional_role_options_freelance_teacher },
    { value: 'academic', text: otcPlatformRegistrationForm.professional_role_options_academic },
    { value: 'other', text: otcPlatformRegistrationForm.professional_role_options_other }
  ];

  return (
    <div className={styles.Container}>
      <Heading text={otcPlatformRegistrationForm.heading} variant="h1" />

      <p className={styles.Paragraph}>{otcPlatformRegistrationForm.paragraph_one}</p>

      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Card
          className={styles.Card}
          headingText={otcPlatformRegistrationForm.institution_details_form_heading}
          headingColor="register"
        >
          <div className={styles.FormContainer}>
            <TextInput label={otcPlatformRegistrationForm.institution_name_label} required />
            <TextInput label={otcPlatformRegistrationForm.institution_address_label1} required />
            <TextInput label={otcPlatformRegistrationForm.institution_address_label2} />
            <TextInput label={otcPlatformRegistrationForm.institution_address_label3} />
            <TextInput label={otcPlatformRegistrationForm.institution_town_label} required />
            <TextInput label={otcPlatformRegistrationForm.institution_postcode_label} />
            <Select
              label={otcPlatformRegistrationForm.institution_country_label}
              required
              options={Object.entries(countryCodes).map(([value, text]) => ({ value, text }))}
            />
          </div>

          <p className={styles.Paragraph}>
            <strong> {otcPlatformRegistrationForm.personal_information_paragraph_one} </strong>
          </p>

          <p className={styles.Paragraph}>
            {otcPlatformRegistrationForm.personal_information_paragraph_two}
            <TextLink to="https://todo"> {otcPlatformRegistrationForm.personal_information_link_text}</TextLink>
          </p>
        </Card>

        <Card
          className={styles.Card}
          headingText={otcPlatformRegistrationForm.preferences_form_heading}
          headingColor="register"
        >
          <div className={styles.FormContainer}>
            <Select
              label={otcPlatformRegistrationForm.professional_role_label}
              required
              options={professionalRoleDropdownOptions}
            />
            <TextInput label={otcPlatformRegistrationForm.professional_role_other_label} />
            <CheckboxGroup
              label={otcPlatformRegistrationForm.professional_interest_label}
              items={professionalInterestCheckboxOptions}
              variant="outline"
            />
            <TextInput label={otcPlatformRegistrationForm.where_did_you_hear_about_us_label} />
          </div>
        </Card>

        <Card
          className={styles.Card}
          headingText={otcPlatformRegistrationForm.terms_and_conditions_heading}
          headingColor="register"
        >
          <p className={styles.Paragraph}> {otcPlatformRegistrationForm.terms_and_conditions_paragraph_one} </p>

          <TextLink to="https://todo"> {otcPlatformRegistrationForm.privacy_policy_link_text} </TextLink>
          <TextLink to="https://todo"> {otcPlatformRegistrationForm.legal_notice_link_text} </TextLink>
        </Card>

        <Button
          variant="filled"
          type="submit"
          text={otcPlatformRegistrationForm.button_save_text}
          ariaAttributes={{ label: otcPlatformRegistrationForm.button_save_text }}
        />
      </form>
    </div>
  );
}

OTCPlaformRegistrationForm.propTypes = {
  localizedContent: propTypes.shape({
    countryCodes: propTypes.object,
    otcPlatformRegistrationForm: propTypes.object
  }).isRequired
};

export default withLocalizedContent('countryCodes', 'otcPlatformRegistrationForm')(OTCPlaformRegistrationForm);
